import FacebookIcon from './Icon/FacebookIcon';
import InstagramIcon from './Icon/InstagramIcon';
import EmailIcon from './Icon/EmailIcon';
import PinterestIcon from './Icon/PinterestIcon';
import PrintIcon from './Icon/PrintIcon';
import TiktokIcon from './Icon/TiktokIcon';
import XIcon from './Icon/XIcon';
import YoutubeIcon from './Icon/YoutubeIcon';

const Social = {
	facebook: {
		image: '/icons/facebook-social-icon.svg',
		displayName: 'Facebook',
		url: 'https://www.facebook.com/flavournetworkca',
		Icon: FacebookIcon,
	},
	pinterest: {
		image: '/icons/pinterest-social-icon.svg',
		displayName: 'Pinterest',
		url: 'https://www.pinterest.ca/FlavourNetwork/',
		Icon: PinterestIcon,
	},
	youtube: {
		image: '/icons/youtube-social-icon.svg',
		displayName: 'Youtube',
		url: 'https://www.youtube.com/@FlavourNetworkCa',
		Icon: YoutubeIcon,
	},
	instagram: {
		image: '/icons/instagram-social-icon.svg',
		displayName: 'Instagram',
		url: 'https://www.instagram.com/flavour.network',
		Icon: InstagramIcon,
	},
	x: {
		image: '/icons/twitter-social-icon.svg',
		displayName: 'X',
		url: 'https://x.com/Flavour_Network',
		Icon: XIcon,
	},
	tiktok: {
		image: '/icons/tiktok-social-icon.svg',
		displayName: 'Tik Tok',
		url: 'https://www.tiktok.com/@flavour.network',
		Icon: TiktokIcon,
	},
	email: {
		image: '/icons/email-social-icon.svg',
		displayName: 'Email',
		Icon: EmailIcon,
	},
	print: {
		image: '/icons/print-social-icon.svg',
		displayName: 'Print',
		Icon: PrintIcon,
	},
};

export default Social;
